import Cookies from 'universal-cookie';
import { useEffect } from 'react';
const cookies = new Cookies();
export const useSocket = (url) => {
    let socket = null;
    const getInstanceSocket = () => {
        const token = cookies.get('token');
        const isHttps = window.location.protocol === 'https:';
        return new WebSocket(`${isHttps ? 'wss' : 'ws'}://demo.api.harvester.so:${isHttps ? '' : '10202'}/${url}`, ['Bearer', token]);
    };
    useEffect(() => {
        socket = getInstanceSocket();
    }, []);
    return socket;
};
