import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Text } from '@/shared/ui/Text/Text';
import { forwardRef } from 'react';
import { Icon } from '@/shared/ui/Icon/Icon';
import { ReactComponent as Date } from '@/shared/assets/icons/datePicker.svg';
const DatePickerInput = forwardRef(({ value, onClick }, forwardedRef) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (_jsxs(S.DatePickerInputContainer, { onClick: handleClick, ref: () => forwardedRef, children: [_jsx(Text, { css: { whiteSpace: 'nowrap', color: '#11263C', fontSize: '14px' }, type: "labelText", children: value }), _jsx(Icon, { css: { width: '13px', height: '15px', margin: '0' }, IconItem: Date })] }));
});
export { DatePickerInput };
