import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, useMemo } from 'react';
import DatePickerPrimitive, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';
import ru from 'date-fns/locale/ru';
import * as S from './style';
import { Space } from '@/shared/ui/Space/Space';
import { Text } from '@/shared/ui/Text/Text';
import moment from 'moment';
import { DatePickerInput } from '@/shared/ui/DatePickerInput/DatePickerInput';
import { Switch } from '@/shared/ui';
import { iconDictionary } from '@/shared/iconDictionary/icon-dictionray';
registerLocale('ru', ru);
const Header = ({ monthDate, customHeaderCount, decreaseMonth, increaseMonth, isDesktop, type, }) => {
    return (_jsxs(Space, { css: { padding: '16px 16px 8px 16px', borderRadius: '16px' }, content: "between", align: "center", children: [customHeaderCount === 0 && (_jsx("div", { style: { cursor: 'pointer' }, onClick: decreaseMonth, children: iconDictionary.DATELEFT })), customHeaderCount === 1 && _jsx("div", { style: { width: 40 } }), _jsx("div", { className: "react-datepicker__current-month", children: monthDate.toLocaleString('ru-RU', {
                    month: 'long',
                    year: 'numeric',
                }).replace('г.', '') }), (customHeaderCount === 0 && type !== 'promocode' && type !== 'mailing') && _jsx("div", { style: { width: 40 } }), (customHeaderCount === 1 || !isDesktop || type === 'promocode' || type === 'mailing') && (_jsx("div", { style: { cursor: 'pointer' }, onClick: increaseMonth, children: iconDictionary.DATERIGHT }))] }));
};
const CustomInput = forwardRef(({ value, onClick, type, setChecked, checked }, ref) => {
    let startDate = '';
    let endDate = '';
    let mailingDate = '';
    const checkChecked = typeof checked === 'undefined' ? true : checked;
    if (value && checkChecked) {
        const [firstValue, secondValue] = value.split(' - ');
        startDate = moment(firstValue).format('D MMM YYYY');
        endDate = moment(secondValue).format('D MMM YYYY');
    }
    if (!checkChecked && value) {
        endDate = moment(value).format('D MMM YYYY');
    }
    if (type === 'mailing' && value) {
        mailingDate = moment(value).format('D MMM YYYY');
    }
    return (_jsxs(Space, { css: { width: '100%' }, align: "center", children: [type === 'mailing' && (_jsx(DatePickerInput, { value: mailingDate === 'Invalid date' ? '' : mailingDate, ref: ref, onClick: onClick })), type === 'default' && (_jsxs(_Fragment, { children: [_jsx(DatePickerInput, { value: startDate === 'Invalid date' ? '' : startDate, ref: ref, onClick: onClick }), _jsx(Text, { css: { margin: '0 4px' }, type: "labelText", children: "-" }), _jsx(DatePickerInput, { value: endDate === 'Invalid date' ? '' : endDate, ref: ref, onClick: onClick })] })), type === 'promocode' && (_jsxs(Space, { size: "large", children: [_jsxs(Space, { size: "default", direction: "vertical", children: [_jsxs(Space, { size: "default", children: [_jsx(Switch, { color: "green", checked: checked, setChecked: setChecked }), _jsx(Text, { disable: !checked, children: "\u0414\u0430\u0442\u0430 \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0438" })] }), checked ?
                                _jsx(DatePickerInput, { value: startDate === 'Invalid date' ? '' : startDate, ref: ref, onClick: onClick }) :
                                _jsx(Text, { css: { color: '$acceptButton' }, children: "\u0414\u043E\u0441\u0442\u0443\u043F\u0435\u043D \u0441\u0440\u0430\u0437\u0443" })] }), _jsxs(Space, { size: "default", direction: "vertical", children: [_jsx(Text, { children: "\u0414\u0430\u0442\u0430 \u043E\u043A\u043E\u043D\u0447\u0430\u043D\u0438\u044F" }), _jsx(DatePickerInput, { value: endDate === 'Invalid date' ? '' : endDate, ref: ref, onClick: onClick })] })] }))] }));
});
const Container = ({ children }) => {
    return (_jsx(Space, { css: {
            background: 'white',
            boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
            border: '1px solid $border',
            borderRadius: 16,
        }, size: "small", children: _jsx("div", { children: _jsx(Space, { children: children }) }) }));
};
const renderDayContents = (day) => {
    return (_jsx("div", { style: { width: '100%', borderRadius: '50%' }, children: day }));
};
export const DatePicker = (props) => {
    const isDesktop = window.innerWidth >= 1025;
    const { startDate, handleClickDatePicker, endDate, error, onChange, minDateValue, errorMessage, onBlur, disabled, maxDate, type = 'default', checked, setChecked, selected, } = props;
    const currentDate = new Date();
    const selectRange = typeof checked === 'undefined' ? true : checked;
    const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
    const getMonthShow = useMemo(() => {
        if (type === 'promocode' || type === 'mailing') {
            return 1;
        }
        return isDesktop ? 2 : 1;
    }, []);
    const isError = () => typeof error === 'object';
    return (_jsxs(S.Wrapper, { children: [_jsx(DatePickerPrimitive, { onInputClick: handleClickDatePicker, customInput: _jsx(CustomInput, { type: type, checked: checked, setChecked: setChecked }), monthsShown: getMonthShow, locale: "ru", selected: selected, minDate: minDateValue ? minDateValue : minDate, maxDate: maxDate ? maxDate : new Date(), selectsRange: selectRange, startDate: startDate, endDate: endDate, renderCustomHeader: (propsHeader) => (_jsx(Header, Object.assign({ type: type, isDesktop: isDesktop }, propsHeader))), calendarContainer: (datePickerProps) => (_jsx(Container, Object.assign({}, datePickerProps))), popperPlacement: isDesktop ? 'top' : 'top-end', renderDayContents: renderDayContents, onChange: onChange, onBlur: onBlur, disabled: disabled, showPopperArrow: false, focusSelectedMonth: true }), isError() && _jsx(S.Error, { children: errorMessage })] }));
};
